@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
}
/* Zertifikat Information */
ul {
  border: 0.5px solid black;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
}
ul div {
  display: flex;
}
ul li {
  min-width: 150px;
  border-right: 0.5px solid black;
}

li p:nth-child(1) {
  text-align: center;
  background: black;
  color: white;
  line-height: 56px;
  font-weight: bold;
}

li p:nth-child(2) {
  padding: 10px 10px;
  line-height: 28px;
}

ul div:nth-child(2) li:nth-child(3) {
  border: none;
}

@media screen and (max-width: 1080px) {
  ul {
    display: grid;
    gap: 2rem;
    border: none;
    justify-content: center;
  }

  ul div {
    width: fit-content;
    overflow: hidden;
    border: 0.5px solid black;
    border-radius: 10px;
  }

  ul div:nth-child(1) li:nth-child(3) {
    border-right: none;
  }

  ul div li:nth-child(3) p:nth-child(2) {
    border: none;
  }
}

@media screen and (max-width: 650px) {
  ul {
    gap: 0;
    border-radius: 0px;
  }

  ul div {
    border-radius: 0px;
    overflow: visible;
    display: grid;
    border: none;
  }

  ul div li {
    text-align: center;
    margin-bottom: 10px;
    border: 0.5px solid black;
    border-radius: 10px;
    overflow: hidden;
  }
  ul div {
    width: 100%;
  }

  ul div:nth-child(1) li:nth-child(3) {
    border-right: 0.5px solid black;
  }

  ul div:nth-child(2) li:nth-child(3) {
    border: 0.5px solid black;
  }
}
